import { Form } from "@/store/form";
import Echo from "laravel-echo";
import store from "@/store";

window._ = require("lodash");
window.axios = require("axios");
window.Form = Form;
window.io = require("socket.io-client");
window.Echo = new Echo({
    broadcaster: "socket.io",
    host: window.location.hostname,
    authEndpoint: "/api/v1/broadcasting/auth",
    forceTLS: true,
    encrypted: false,
    auth: {
        headers: {
            Authorization: "Bearer " + store.getters.getAuthToken
        }
    }
});
