<template>
  <v-app>
    <router-view></router-view>
    <template>
      <footer>
        <cookie-law class="primary">
          <template slot-scope="props">
            <v-row>
              <v-col cols="7"
                ><p class="white--text my-auto">
                  We use our own cookies and third-party cookies so that we can show you this website and better
                  understand how you use it, with a view to improving the services we offer. If you continue browsing,
                  we consider that you have accepted the cookies.
                </p>
              </v-col>
              <v-col cols="4" class="offset-1 d-flex justify-end">
                <p class="my-auto">
                  <v-btn class="btn black--text mr-1" @click="props.close"><span>Close</span></v-btn>
                  <v-btn class="btn black--text" color="accent" @click="props.accept"><span>I accept</span></v-btn>
                </p>
              </v-col>
            </v-row>
          </template>
        </cookie-law>
      </footer>
    </template>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  components: { CookieLaw },
  data: () => ({})
};
</script>
