require("./bootstrap");

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import moment from "moment-timezone";
import VueMoment from "vue-moment";
import vuetify from "./plugins/vuetify";
import InfiniteLoading from "vue-infinite-loading";
import "./plugins/filters";
import "./scss/_main.scss";

Vue.config.productionTip = false;
Vue.use(VueMoment, { moment });
Vue.use(InfiniteLoading, {
    props: {
        spinner: "spiral"
    },
    system: {
        throttleLimit: 50
    },
    slots: {
        noResults: "No results!",
        noMore: "End of results!"
    }
});

Vue.component("MiceNavigation", () => import(/* webpackChunkName: "mice-navigation" */ "@/components/MiceNavigation"));
Vue.component("OrganizationSelector", () =>
    import(/* webpackChunkName: "mice-organization-selector" */ "@/components/form/OrganizationSelector")
);

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount("#app");
